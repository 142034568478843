import decode from 'jwt-decode';
import moment from 'moment';

export function getTokenExpirationDate(token) {
  const decoded: any = decode(token);
  if (!decoded.exp) {
    return null;
  }
  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
}

export function isTokenExpired(token) {
  const date = getTokenExpirationDate(token);
  const offsetSeconds = 0;
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

export const dateDisplay = (latestMessageTime) => {
  if (!latestMessageTime) {
    return null;
  }
  const now = moment();
  if (moment(now).isSame(latestMessageTime, 'day')) {
    latestMessageTime = moment(latestMessageTime).format('h:mm a');
    return latestMessageTime;
  }
  latestMessageTime = moment(latestMessageTime).format('YYYY/MM/DD');
  return latestMessageTime;
};

const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateString = (length) => {
  let result = '';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const generateRandomFileName = (originalName: string): string => {
  const randomString = generateString(32) // Generate a random string
  const fileExtension = originalName.split('.').pop(); // Extract the original file extension
  return `${randomString}.${fileExtension}`;
};

export const isValidImage = (file: File): boolean => {
  const allowedTypes = ['image/jpeg', 'image/png'];
  return allowedTypes.includes(file.type);
};

