import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import { ImgDetail } from '@src/components/Ports/components/ImagDetail';
import React, { useCallback, useState } from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import { AddDoorToPortModal } from './components/AddDoorToPortModal';
import { DeleteDoorModal } from './components/DeleteDoorModal';
import { DoorModal } from './components/DoorModal';
import { PortModal } from './components/PortModal';
import { PortRow } from './components/Row';
import {PlusOutlined} from "@ant-design/icons";
import useWindowDimensions from "@src/hooks/useWindowDimensions";
type imageUrlComponentType = {
  imageUrl: string | null;
  imageUrlKey: string;
  portId: string | null;
};
export const PortsComponent = ({
  ports = [],
  state = {},
  onChangeHandler,
  handleOpen,
  handleClose,
  editDoorId,
  doorId,
  portId,
  setDeleteDoor,
  addDoorToPort,
  addDoorToPortCancel,
  setAddDoorToPort,
  editDoor,
  openDoor,
  deleteDoor,
  deleteDoorCancel,
  updateDoor,
  updateDoorCancel,
  doorCode,
  addPort,
  isSystemAdmin,
  setUpdatePort,
  updatePortImageSoftDelete,
  updatePort,
  checkRasPi,
  rebootRasPi,
  refetch,
  getGPSGoogleMap,
  seedGoogleMap,
  setModalIndex
}: any) => {
  const [searchedPort, setSearchedPort] = useState();
  const [statePort, setPortState] = useState('');
  const imageDate = new Date().toLocaleString('ja');
  const { width } = useWindowDimensions();

  const handleChange = (e) => {
    if (e.target.value) {
      setSearchedPort(e.target.value.split("/")[0]);
      setPortState(e.target.value.split("/")[1]);
    } else {
      setSearchedPort(undefined);
      setPortState("");
    }
  };
  const updateImageSoftDelete = async ({ portId, imageUrlKey }) => {
    await updatePortImageSoftDelete({
      portId: portId,
      imageUrlKey: imageUrlKey,
    });
  };

  const imageUrlComponent = useCallback(
    ({ imageUrl, imageUrlKey, portId }: imageUrlComponentType) => {
      if (imageUrl) {
        return (
          <div
            key={imageUrl}
            style={{
              width: '33%',
              flexDirection: 'row',
              display: 'flex',
              marginRight: 5,
            }}>
            <AspectRatio ratio={0.75} style={{ maxWidth: '300px' }}>
              <img
                src={`${imageUrl}?${imageDate}`}
                width="100%"
                alt={imageUrl}></img>
            </AspectRatio>
            <div
              style={{
                boxShadow: 'none',
                position: 'absolute',
                backgroundColor: '#ffffff',
                borderRadius: '50%',
              }}>
              <IconButton
                size="small"
                onClick={() =>
                  updateImageSoftDelete({
                    portId: portId,
                    imageUrlKey: imageUrlKey,
                  })
                }>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        );
      } else return null;
    },
    [refetch],
  );
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{
          display: 'flex',
          paddingBottom: '20px',
          alignItems: width < 700 ? 'flex-start' : 'flex-end',
          flexDirection: width < 700 ? 'column' : 'row'
        }}>
        <h1 style={{ marginRight: '10px', fontSize: width < 700 ? '12px' : '15px', fontWeight: '600', whiteSpace: 'nowrap' }}>ポートを選択</h1>
        <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '500px', width: '100%' }}>
          <FormControl
            style={{ maxWidth: '250px', width: '100%', marginRight: '10px', fontSize: width < 700 ? '11px' : '14px' }}>
            <InputLabel id="ports-label">{statePort}</InputLabel>
            <Select
              // value={statePort}
              onChange={handleChange}
              id="ports-select"
              labelId="ports-label">
              <MenuItem value="" style={{ fontSize: width < 700 ? '11px' : '14px' }}>
                <em>ポート名</em>
              </MenuItem>
              {React.Children.toArray(ports.map((port) => (
                <MenuItem value={port.id + "/" + port.name} style={{ fontSize: width < 700 ? '11px' : '14px' }}>{port.name}</MenuItem>
              )))}
            </Select>
          </FormControl>
          <Button
            onClick={handleOpen}
            style={{ height: '100%', boxShadow: 'none', whiteSpace: 'nowrap', fontSize: width < 700 ? '11px' : '' }}
            variant="contained"
            color="primary">
            <PlusOutlined style={{ marginRight: '6px' }}/> 新規作成
          </Button>
        </div>
      </div>
      <DeleteDoorModal
        doorId={doorId}
        deleteDoor={deleteDoor}
        deleteDoorCancel={deleteDoorCancel}
      />
      <DoorModal
        doorCode={doorCode}
        onChangeHandler={onChangeHandler}
        editDoorId={editDoorId}
        updateDoor={updateDoor}
        updateDoorCancel={updateDoorCancel}
      />
      <PortModal
        state={state}
        setModalIndex={setModalIndex}
        onChangeHandler={onChangeHandler}
        handleClose={handleClose}
        addPort={addPort}
        updatePort={updatePort}
        getGPSGoogleMap={getGPSGoogleMap}
        seedGoogleMap={seedGoogleMap}
      />
      <AddDoorToPortModal
        portId={portId}
        addDoorToPort={addDoorToPort}
        addDoorToPortCancel={addDoorToPortCancel}
      />
      <Paper elevation={2}>
        {React.Children.toArray(ports.map((port) => {
          if (searchedPort === undefined || searchedPort === port.id) {
            return (
              <div id={port.id} style={{ display: 'block' }}>
                <h2
                  style={{
                    display: 'flex',
                    paddingTop: '10px',
                    paddingLeft: '20px',
                    fontWeight: '600',
                    fontSize: width < 700 ? '12px' : '15px'
                  }}>
                  {port.name}
                </h2>
                <div>
                  <div
                    className={"123123"}
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                    }}>
                    {imageUrlComponent({
                      imageUrl: port?.imageUrl1,
                      imageUrlKey: 'imageUrl1',
                      portId: port?.id,
                    })}
                    {imageUrlComponent({
                      imageUrl: port?.imageUrl2,
                      imageUrlKey: 'imageUrl2',
                      portId: port?.id,
                    })}
                    {imageUrlComponent({
                      imageUrl: port?.imageUrl3,
                      imageUrlKey: 'imageUrl3',
                      portId: port?.id,
                    })}
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                    }}>
                    <ImgDetail id={port.id} isPort={true} />
                  </div>
                </div>
                <div
                  style={{
                    padding: '20px',
                    height: 'auto',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: width < 592 ? 'column' : 'row',
                    rowGap: '20px'
                  }}>
                  <div style={{ display: 'flex', flexDirection: width < 400 ? 'column' : 'row', gap: '10px', }}>
                    <Button
                      style={{ width: width < 400 ? '100%' : 'max-content', height: '36px' ,marginRight: '10px', boxShadow: 'none', whiteSpace: 'nowrap', fontSize: width < 700 ? '11px' : '' }}
                      variant="contained"
                      color="primary"
                      onClick={() => setAddDoorToPort(port.id)}>
                      ドア追加
                    </Button>
                    <Button
                      style={{ width: width < 400 ? '100%' : 'max-content', height: '36px', marginRight: '10px', boxShadow: 'none', backgroundColor: '#eab308', whiteSpace: 'nowrap', fontSize: width < 700 ? '11px' : '' }}
                      variant="contained"
                      color="primary"
                      onClick={() => setUpdatePort(port)}>
                      ポート編集
                    </Button>
                    <Button
                      style={{
                        width: width < 400 ? '100%' : 'max-content',
                        height: '36px',
                        marginRight: '10px',
                        boxShadow: 'none',
                        backgroundColor: '#16a34a',
                        whiteSpace: 'nowrap',
                        fontSize: width < 700 ? '11px' : ''
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => checkRasPi(port.rasPiId)}>
                      ラズパイチェック
                    </Button>
                  </div>
                  <Button
                    style={{
                      width: 'max-content',
                      height: '36px',
                      marginRight: '10px',
                      boxShadow: 'none',
                      backgroundColor: '#e5e7eb',
                      whiteSpace: 'nowrap',
                      fontSize: width < 700 ? '11px' : ''
                    }}
                    variant="contained"
                    color="default"
                    onClick={() => rebootRasPi(port.rasPiId)}>
                    ラズパイ再起動
                  </Button>
                </div>
                <div style={{ width: '100%', overflowX: "auto" }}>
                  <Table style={{ marginBottom: '50px'}}>
                    <TableHead style={{ backgroundColor: '#f0f2f5' }}>
                      <TableRow>
                        <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap" }}>ドア番号</TableCell>
                        <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap" }}>ステータス</TableCell>
                        <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap" }}>ベビーカーの状態</TableCell>
                        <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap" }}>開閉状態</TableCell>
                        <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap" }}>解錠する</TableCell>
                        <TableCell style={{ fontWeight: 600, whiteSpace: "nowrap" }}>ドア編集</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {port.doors.map((door, index) => (
                        <PortRow
                          key={door.id}
                          port={port}
                          door={door}
                          openDoor={openDoor}
                          index={index}
                          editDoor={editDoor}
                          setDeleteDoor={setDeleteDoor}
                          setAddDoorToPort={setAddDoorToPort}
                          isSystemAdmin={isSystemAdmin}
                          setUpdatePort={setUpdatePort}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            );
          }
          return null;
        }))}
      </Paper>
    </div>
  );
};
