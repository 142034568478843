// eslint-disable-next-line simple-import-sort/imports
import {DeleteObjectCommand, PutObjectCommand, S3Client} from '@aws-sdk/client-s3';
import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';

import { useMutation } from '@apollo/client';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { MessageType } from '@src/generated/graphql';
import { decode } from 'base64-arraybuffer';
import {
  SEND_MESSAGE_MUTATION,
  SEND_PUBLIC_MESSAGE_MUTATION,
  UPDATE_FACILITY_IMAGE,
  UPDATE_PORT_IMAGE,
} from './request';

const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_BUCKET,
  REACT_APP_AWS_IDENTITYPOOLID,
  REACT_APP_AWS_PUBLIC_BUCKET,
  REACT_APP_AWS_PUBLIC_URL_TOP_SLIDER
} = process.env;

const awsConfig = {
  region: REACT_APP_AWS_REGION,
  IdentityPoolId: REACT_APP_AWS_IDENTITYPOOLID as string,
};
AWS.config.region = awsConfig.region;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: awsConfig.IdentityPoolId,
});
const region = REACT_APP_AWS_REGION;
const poolId = REACT_APP_AWS_IDENTITYPOOLID;
const s3Client = new S3Client({
  region,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region }),
    identityPoolId: poolId as string,
  }),
});
type uploadS3ImageType = {
  uri: string;
  meId: string | null;
  chatId?: string | null;
  isPublic: boolean;
};
const s3 = new AWS.S3();

export const uniqueString = (): string => {
  const today = new Date();
  const returnString = `${
    today.getFullYear().toString() +
    (today.getMonth() + 1).toString() +
    today.getDate().toString()
  }_${today.getHours().toString()}:${today.getMinutes().toString()}:${today
    .getSeconds()
    .toString()}`;

  return returnString;
};

export const useS3Image = () => {
  const [sendMessage] = useMutation(SEND_MESSAGE_MUTATION);
  const [sendPublicMessage] = useMutation(SEND_PUBLIC_MESSAGE_MUTATION);
  const [updateFacilityImage] = useMutation(UPDATE_FACILITY_IMAGE);
  const [updatePortImage] = useMutation(UPDATE_PORT_IMAGE);

  const uploadS3Image = async ({
    uri,
    meId,
    isPublic,
    chatId,
  }: uploadS3ImageType) => {
    const bucket = REACT_APP_AWS_BUCKET as string;
    try {
      const base64EncodedFile = uri.replace(/data:.*\/.*;base64,/, '');
      const buffer = decode(base64EncodedFile);
      const dateString = uniqueString();
      const params = {
        Bucket: bucket,
        Key: `${meId}ChatImage${dateString}`,
        Body: buffer,
      };

      s3.putObject(params, (err, data: S3.PutObjectOutput | any) => {
        if (err) {
          console.log('Err: upload failed :' + err);
        } else {
          console.log('Success: upload ok', data);
        }
      });
      if (isPublic) {
        try {
          await sendPublicMessage({
            variables: {
              email: meId,
              text: '',
              messageType: MessageType.IMAGE,
              imageKey: `${meId}ChatImage${dateString}`,
              imageBucket: bucket,
            },
          });
        } catch (e) {
          console.log('mutation', e);
        }
      } else if (!isPublic && chatId) {
        try {
          await sendMessage({
            variables: {
              chatId: chatId,
              text: '',
              messageType: MessageType.IMAGE,
              imageKey: `${meId}ChatImage${dateString}`,
              imageBucket: bucket,
            },
          });
        } catch (e) {
          console.log('mutation', e);
        }
      }
    } catch (error) {
      console.log('erroe', { error });
    }
  };
  const uploadS3FacilityImage = async ({
    uri,
    facilityId,
    imageUrlKey,
  }: any) => {
    const bucket = REACT_APP_AWS_PUBLIC_BUCKET as string;
    try {
      const base64EncodedFile = uri.replace(/data:.*\/.*;base64,/, '');
      const buffer = decode(base64EncodedFile);
      const params = {
        Bucket: bucket,
        Key: `facility${facilityId}${imageUrlKey}`,
        ACL: 'public-read',
        Body: buffer as any,
      };
      console.log(params, 'params');
      const data = await s3Client.send(new PutObjectCommand(params));
      console.log('Success', data);

      try {
        await updateFacilityImage({
          variables: {
            facilityId: facilityId,
            data: {
              [imageUrlKey]: `https://${bucket}.s3.ap-northeast-1.amazonaws.com/facility${facilityId}${imageUrlKey}`,
            },
          },
        });
      } catch (e) {
        console.log('mutation', e);
      }
    } catch (error) {
      console.log('erroe', { error });
    }
  };

  const uploadS3PortImage = async ({ uri, portId, imageUrlKey }: any) => {
    const bucket = REACT_APP_AWS_PUBLIC_BUCKET as string;
    try {
      const base64EncodedFile = uri.replace(/data:.*\/.*;base64,/, '');
      const buffer = decode(base64EncodedFile);
      const params = {
        Bucket: bucket,
        Key: `port${portId}${imageUrlKey}`,
        ACL: 'public-read',
        Body: buffer as any,
      };
      console.log(params, 'params');
      const data = await s3Client.send(new PutObjectCommand(params));
      console.log('Success', data);

      try {
        await updatePortImage({
          variables: {
            portId: portId,
            data: {
              [imageUrlKey]: `https://${bucket}.s3.ap-northeast-1.amazonaws.com/port${portId}${imageUrlKey}`,
            },
          },
        });
      } catch (e) {
        console.log('mutation', e);
      }
    } catch (error) {
      console.log('erroe', { error });
    }
  };

  const uploadS3TopSliderImage = async (file: File, fileName: string) => {
    try {
      const bucket = REACT_APP_AWS_PUBLIC_BUCKET as string;
      const params = {
        Bucket: bucket,
        Key: `TopSliders_use_image/${fileName}`,
        ACL: 'public-read',
        Body: file,
        ContentType: file.type,
      };
      const response = await s3Client.send(new PutObjectCommand(params))
      if (response.$metadata.httpStatusCode) {
        return `${REACT_APP_AWS_PUBLIC_URL_TOP_SLIDER}${fileName}`;
      }
      return null;
    } catch (error) {
      console.log('erroe', { error });
      return null
    }
  }

  const removeFileFromS3 = async (bucketName: string, fileKey: string): Promise<void> => {
    try {
      const params = {
        Bucket: bucketName,
        Key: fileKey,
      };

      const data = await s3Client.send(new DeleteObjectCommand(params));
      console.log('File deleted successfully:', data);
    } catch (error) {
      console.error('Error deleting file from S3:', error);
    }
  };

  return { uploadS3Image, uploadS3FacilityImage, uploadS3PortImage, uploadS3TopSliderImage, removeFileFromS3 };
};
