import React, {useEffect, useRef, useState} from 'react';
import {Form, Input, InputNumber, Space, Table} from "antd";
import Button from "@material-ui/core/Button";
import useWindowDimensions from "@src/hooks/useWindowDimensions";
import {makeStyles} from "@material-ui/core/styles";
import {CloseOutlined, EditOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import './style.css'
import {generateRandomFileName, generateString, isValidImage} from "@src/utils";

const {
  REACT_APP_AWS_PUBLIC_BUCKET,
} = process.env;

interface ITopSlider {
  id?: string;
  imageUrl?: string;
  clickUrl?: string;
  order: number | null,
  createdAt?: string,
  updatedAt?: string,
  deletedAt?: string,
  key: string;
}

interface IProps {
  topSliders: ITopSlider[];
  refetch: any;
  showAlert: any;
  uploadS3TopSliderImage: any;
  removeFileFromS3: any;
  upsertTopSliders: any;
  adminUpdateTopSlider: any;
  adminDeleteTopSlider: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  toolbar: {
    display: 'flex',
    flex: '1 1 100%',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  buttonAdd: {
    borderTop: '1px solid #f0f0f0',
    borderLeft: '1px solid #f0f0f0',
    borderRight: '1px solid #f0f0f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '12px'
  }
}));


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: ITopSlider;
  index: number;
  children: React.ReactNode;
  inputFile: any
  handleFileChange: any
}

const EditableCell: React.FC<EditableCellProps> = (
  {
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    inputFile,
    handleFileChange,
    ...restProps
  }
) => {
  if (dataIndex === "imageUrl") {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={[record.key, "imageUrl"]}
            style={{margin: 0}}
            rules={[
              {
                required: true,
                message: "You must upload an image.",
              },
            ]}
          >
            <div style={{position: "relative"}}>
              {record && record.imageUrl ? (
                <img
                  style={{
                    width: "350px",
                    height: "200px",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  onClick={() => inputFile.current.click()} // Trigger file input click
                  src={record.imageUrl}
                  alt="slider-1"
                />
              ) : (
                <div
                  style={{
                    width: "350px",
                    height: "200px",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => inputFile.current.click()}
                >
                  <span>Choose an image</span>
                </div>
              )}
              <input
                type="file"
                ref={inputFile}
                accept="image/*"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
                onChange={(e) =>
                  handleFileChange(e.target.files?.[0] || null, record)
                }
              />
            </div>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }
  const inputNode = inputType === 'number' ? <InputNumber/> : <Input/>;

  return (
    <td {...restProps}>
      {editing ? (<Form.Item
          name={[record.key, dataIndex]}
          style={{margin: 0, width: '100%'}}
          rules={
            dataIndex === 'order'
              ? [
                {required: true, message: `${title} is required.`},
                {
                  validator: (_, value) => {
                    if (value === undefined || value === null || value === '') {
                      return Promise.reject(new Error(`${title} must be a number.`));
                    }
                    if (typeof value !== 'number' || isNaN(value)) {
                      return Promise.reject(new Error(`${title} must be a valid number.`));
                    }
                    if (value < 1) {
                      return Promise.reject(new Error(`${title} must be greater than 1.`));
                    }
                    return Promise.resolve();
                  },
                },
              ]
              : [] // No validation for other fields
          }
        >
          {inputNode}
        </Form.Item>)
        : (
          children
        )}
    </td>
  );
};
const TopSliderList = ({
                         topSliders,
                         uploadS3TopSliderImage,
                         upsertTopSliders,
                         refetch,
                         adminUpdateTopSlider,
                         adminDeleteTopSlider,
                         showAlert,
                         removeFileFromS3
                       }: IProps) => {
  const [form] = Form.useForm();
  const classes = useStyles();
  const {width} = useWindowDimensions();
  const [isEdit, setIsEdit] = useState(false);
  const [dataSource, setDataSource] = useState<ITopSlider[]>([]);
  const inputFile = useRef<any>(null);
  const [tempImages, setTempImages] = useState<Record<string, File | null>>({});

  useEffect(() => {
    if (topSliders) {
      if (topSliders.length > 0) {
        setDataSource(topSliders.map(item => ({...item, key: generateString(10)})));
      } else {
        setDataSource(topSliders);
      }
    }
  }, [topSliders]);

  const edit = (records: Partial<ITopSlider[]>) => {
    records.forEach((record) => {
      // @ts-ignore
      form.setFieldsValue({[record?.key]: record});
    });
  };

  const handleAddMore = () => {
    const newRow = {
      imageUrl: '',
      clickUrl: '',
      order: null,
      id: '',
      key: generateString(10),
    };

    setDataSource((prevState) => [...prevState, newRow]);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [newRow.key]: newRow,
    });
  };

  const cancel = () => {
    setDataSource((prevState) => prevState.filter(item => item.id !== ''))
    setIsEdit(false)
  };

  const handleFileChange = (file: File | null, record: ITopSlider) => {
    if (!file) return;

    setTempImages((prevState) => ({
      ...prevState,
      [record.key]: file,
    }));

    setDataSource((prevState) =>
      prevState.map((item) =>
        item.key === record.key
          ? {...item, imageUrl: URL.createObjectURL(file)}
          : item
      )
    );
  };

  const save = async () => {
    try {
      const allRows = await form.validateFields();
      const newData = dataSource.map((item) => ({
        ...item,
        ...allRows[item.key],
      }));
      const seenOrders = new Set();
      const duplicateKeys: string[] = [];
      newData.forEach((row) => {
        if (seenOrders.has(row.order)) {
          duplicateKeys.push(row.key);
        }
        seenOrders.add(row.order);
      });

      if (duplicateKeys.length > 0) {
        duplicateKeys.forEach((key) => {
          form.setFields([
            {
              name: [key, 'order'],
              errors: ['Order value must be unique.'],
            },
          ]);
        });
        return;
      }

      const validatedData = await Promise.all(
        newData.map(async (item) => {
          const updatedItem = {...item, ...allRows[item.key]};

          if (tempImages[item.key]) {
            const file = tempImages[item.key];
            if (file) {
              if (!isValidImage(file)) {
                throw new Error('Invalid file type. Only image files are allowed.');
              }
              if (file.size > 8 * 1024 * 1024) {
                throw new Error('File size exceeds the 8MB limit.');
              }

              const fileName = generateRandomFileName(file.name);

              const response = await uploadS3TopSliderImage(file, fileName);
              if (response) {
                updatedItem.imageUrl = response
              }
            }
          }

          return updatedItem;
        })
      );
      setDataSource(validatedData);
      await upsertTopSliders({
        variables: {
          data: validatedData.map((item) => ({
            id: !item.id ? undefined : item.id,
            imageUrl: item.imageUrl,
            clickUrl: item.clickUrl,
            order: item.order,
          }))
        }
      });
      setIsEdit(false);
      await showAlert('SUCCESS', 'success');
      await refetch();
    } catch (errInfo) {
      console.log(errInfo)
    }
  };

  const handleUpdateTopSlider = async (id: string) => {
    const {data: {adminUpdateTopSlider: res}} = await adminUpdateTopSlider({
      variables: {
        id,
      }
    })
    if (res && res.status === 200) {
      showAlert(res.message, 'success');
    }
    await refetch();
  }

  const handleDeleteTopSlider = async (id: string) => {
    const {data: {adminDeleteTopSlider: res}} = await adminDeleteTopSlider({
      variables: {
        id,
      }
    })
    if (res && res.status === 200) {
      showAlert('DELETE SUCCESS', 'success');
      removeFileFromS3(REACT_APP_AWS_PUBLIC_BUCKET, extractKeyFromS3Url(res.message));
    }
    await refetch();
  }

  const extractKeyFromS3Url = (s3Url: string): string => {
    const url = new URL(s3Url);
    return url.pathname.substring(1);
  };

  const columns = [
    {
      title: '順番',
      dataIndex: 'order',
      key: 'order',
      width: '7%',
      editable: true,
    },
    {
      title: '画像',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: '20%',
      editable: true,
      render: (imageUrl: string) => <img style={{width: '350px', height: '200px', objectFit: 'contain'}} src={imageUrl}
                                         alt="slider"/>
    },
    {
      title: 'リンクURL',
      dataIndex: 'clickUrl',
      key: 'clickUrl',
      width: '50%',
      editable: true,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: '12%',
      render: (_, record: any) => {
        if (isEdit) {
          return null;
        }
        return (
          <Space size="middle">
            <Button
              variant="outlined"
              onClick={() => handleUpdateTopSlider(record.id)}
              color={record.deletedAt ? "secondary" : "primary"}
            >
              {record.deletedAt ? '非表示中' : '表示中'}
            </Button>
            <Button
              variant="outlined"
              color="default"
              onClick={() => handleDeleteTopSlider(record.id)}>
              削除
            </Button>
          </Space>
        );
      }
    },
    {
      dataIndex: 'id',
      key: 'id',
      hidden: true
    }
  ].filter(item => !item.hidden);

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ITopSlider) => ({
        record,
        inputType: col.dataIndex === 'order' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEdit,
        inputFile,
        handleFileChange,
      }),
    };
  });

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        {
          !isEdit && <Button
            onClick={() => {
              setIsEdit(true)
              edit(dataSource)
            }}
            style={{height: '100%', boxShadow: 'none', whiteSpace: 'nowrap', fontSize: width < 700 ? '11px' : ''}}
            variant="contained"
            color="primary">
            <EditOutlined style={{marginRight: '6px'}}/> 編集
          </Button>
        }
        {
          isEdit && <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
            <Button
              onClick={save}
              style={{height: '100%', boxShadow: 'none', whiteSpace: 'nowrap', fontSize: width < 700 ? '11px' : ''}}
              variant="contained"
              color="primary">
              <SaveOutlined style={{marginRight: '6px'}}/>保存
            </Button>
            <Button
              onClick={cancel}
              variant="contained"
              color="secondary"
              style={{
                height: '100%',
                boxShadow: 'none',
                whiteSpace: 'nowrap',
                fontSize: width < 700 ? '11px' : '',
              }}>
              <CloseOutlined style={{marginRight: '6px'}}/> キャンセル
            </Button>
          </div>
        }
      </div>
      {
        isEdit && <div className={classes.buttonAdd}>
          <Button
            onClick={handleAddMore}
            color="primary"
            variant="contained"
            style={{
              backgroundColor: '#16a34a',
            }}
          >
            <PlusOutlined style={{marginRight: '6px'}}/> 追加
          </Button>
        </div>
      }
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName={() => 'editable-row'}
          pagination={false}
        />
      </Form>
    </div>
  );
};

export default TopSliderList;