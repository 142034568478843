import {compose, graphql} from 'react-apollo';
import gql from "graphql-tag";
import {useS3Image} from '@src/hooks/useS3Image';
import CircularProgress from "@material-ui/core/CircularProgress";
import {MySnackBar} from "@src/components/MySnackBar";
import TopSliderList from "@src/components/TopSliders/TopSliderList";


const BannersPage = (props: any) => {
  const {
    upsertTopSliders,
    adminUpdateTopSlider,
    adminDeleteTopSlider,
    showAlert,
    data: {
      adminGetTopSliders,
      loading,
      error,
      refetch
    }
  } = props;
  const {uploadS3TopSliderImage, removeFileFromS3} = useS3Image();

  if (loading) return <CircularProgress/>;
  if (error)
    return (
      <MySnackBar variant="error" message={error?.graphQLErrors[0]?.message}/>
    );
  return (
    <div>
      <div style={{fontWeight: '500', fontSize: '24px'}}>バナーTop Slider</div>
      <TopSliderList
        topSliders={adminGetTopSliders}
        refetch={refetch}
        showAlert={showAlert}
        uploadS3TopSliderImage={uploadS3TopSliderImage}
        upsertTopSliders={upsertTopSliders}
        adminUpdateTopSlider={adminUpdateTopSlider}
        adminDeleteTopSlider={adminDeleteTopSlider}
        removeFileFromS3={removeFileFromS3}
      />
    </div>
  );
};

const getTopSliders = gql`
  query AdminGetTopSliders {
    adminGetTopSliders {
      id
      imageUrl
      clickUrl
      order
      deletedAt
      createdAt
      updatedAt
    }
  }
`

const upsertTopSliders = gql`
  mutation AddTopSlider($data: [TopSliderInput]) {
    addTopSlider(data: $data) {
      id
      imageUrl
      clickUrl
      order
      deletedAt
      createdAt
      updatedAt
    }
  }
`
const adminUpdateTopSlider = gql`
  mutation AdminUpdateTopSlider($id: ID!) {
    adminUpdateTopSlider(id: $id) {
      status
      message
    }
  }
`
const adminDeleteTopSlider = gql`
  mutation AdminDeleteTopSlider($id: ID!) {
    adminDeleteTopSlider(id: $id) {
      status
      message
    }
  }
`
export const Banners = compose(
  graphql(upsertTopSliders, {
    name: 'upsertTopSliders'
  }),
  graphql(adminUpdateTopSlider, {
    name: 'adminUpdateTopSlider'
  }),
  graphql(adminDeleteTopSlider, {
    name: 'adminDeleteTopSlider'
  }),
  graphql(getTopSliders, {
    options: {fetchPolicy: 'network-only'}
  })
)(BannersPage);
